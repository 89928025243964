import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography, Link as MuiLink, Checkbox, IconButton } from '@material-ui/core';
import {
  RiProfileLine as RiProfileLineIcon,
  RiMailLine as RiMailLineIcon,
  RiUserLine as UserLineIcon,
  RiPhoneLine as RiPhoneLineIcon,
  RiHistoryLine as RiHistoryLineIcon,
} from 'react-icons/ri';

import {
  Utils,
  RichTextViewer,
  PhaseOptions,
  JurisdictionEnum,
  LandTypeEnum,
  ConservationPlanCategoryEnum,
  WaterBodiesEnum,
  CatalogCodeEnum,
  DueDateAuditTooltip,
} from '@eisc-frontend/shared-ui';
import { useBoards, useCatalog } from '../../adapters';
import { toEISCTime } from '../../utils/functions';

const jurisdictionOptions = Utils.enumToOptions(JurisdictionEnum);
const landTypeOptions = Utils.enumToOptions(LandTypeEnum);
const conservationCategoriesOptions = Utils.enumToOptions(ConservationPlanCategoryEnum);
const waterBodiesEnumOptions = Utils.enumToOptions(WaterBodiesEnum);

interface Board {
  id: number;
  name: string;
}

const DetailsOverview = (props) => {
  const { details, fieldAudits } = props;
  const { getBoards } = useBoards();
  const [boards, setBoards] = useState([]);
  const { data: developmentTypes } = useCatalog(CatalogCodeEnum.TypeDevelopment);
  const { data: regulatorsItems } = useCatalog(CatalogCodeEnum.Regulator);
  const { data: mitigatioMeasuresItems } = useCatalog(CatalogCodeEnum.MitigationMeasures);

  useEffect(() => {
    (async () => {
      const res = await getBoards();
      setBoards(res.data);
    })();
  }, []);

  return (
    <div className="pb-6">
      <div className="flex items-center">
        <RiProfileLineIcon size={32} className="text-secondary" />
        <Typography variant="h2" className="p-0 ml-2 font-semibold" component="div">
          Overview
        </Typography>
      </div>
      <div className="mt-6">
        <Typography variant="subtitle1">1. Developer</Typography>
        <Typography variant="body1">{details.developer?.name}</Typography>
      </div>

      <div className="mt-6">
        <Typography variant="subtitle1">2. Project Title</Typography>
        <Typography variant="body1">{details.title}</Typography>
      </div>

      <div className="flex justify-between mt-6">
        <div>
          <Typography variant="subtitle1">Notice of Proceeding Sent:</Typography>
          <Typography variant="body1">
            {details.itemDistributedAt && toEISCTime(details.itemDistributedAt)}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1">Deadline for Information Requests:</Typography>
          <Typography variant="body1">
            {details.informationRequestDueAt && toEISCTime(details.informationRequestDueAt)}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1">Commenting Period End Date:</Typography>
          <div className="flex items-start">
            <Typography variant="body1">
              {details.commentDueAt && toEISCTime(details.commentDueAt)}
            </Typography>
            {details.commentDueAt && fieldAudits?.CommentDueAt?.length > 0 && (
              <DueDateAuditTooltip
                dueDateFieldName="Comment"
                currentDate={details.commentDueAt}
                extensionDates={fieldAudits.CommentDueAt}
              >
                <IconButton size="medium">
                  <RiHistoryLineIcon size="1.5rem" className="text-primary -m-1" />
                </IconButton>
              </DueDateAuditTooltip>
            )}
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Typography variant="subtitle1">File Number</Typography>
        <Typography variant="body1">{details.fileNumber} </Typography>{' '}
      </div>

      <div className="mt-6">
        <Typography variant="subtitle1">
          3. Please check one of the following indicating your preference:
        </Typography>
        <Typography variant="body1">
          {PhaseOptions.find((el) => el.value === details.initialPhase)?.label || ''}
        </Typography>
      </div>

      <div className="mt-6">
        <Typography variant="subtitle1">4. Project Summary</Typography>
        <RichTextViewer value={details.description} />
      </div>
      <div className="mt-6 flex">
        <div className="flex-150">
          <Typography variant="subtitle1">5. Developer Contact</Typography>
          <div className="flex items-start mt-1">
            <div className="mt-1">
              <UserLineIcon size="1.3em" />
            </div>
            <div className="ml-2">
              <Typography variant="body1" component="div">
                {details.primaryContact?.displayName}
              </Typography>
              <Typography variant="body2" component="div">
                {details.primaryContact?.title}
              </Typography>
            </div>
          </div>
          {details.primaryContact?.contact?.officePhone && (
            <div className="flex items-center mt-2">
              <RiPhoneLineIcon size="1.3em" />
              <Typography variant="body1" component="div" className="ml-2">
                {details.primaryContact?.contact?.officePhone}
              </Typography>
            </div>
          )}
          <MuiLink
            href={`mailto:${details.primaryContact?.email}`}
            color="inherit"
            rel="noopener"
            target="_blank"
            className={'flex items-center mt-2'}
          >
            <RiMailLineIcon size="1.3em" color="#666" />
            <Typography variant="body1" className="ml-2" component="div">
              {details.primaryContact?.email}
            </Typography>
          </MuiLink>
        </div>
        <div className="flex-100">
          <Typography variant="subtitle1">Developer Address</Typography>
          <Typography variant="body1" component="div">
            {[details.developer?.address, details.developer?.province]
              .filter((el) => el)
              .join(', ')}
          </Typography>
          <Typography variant="body1" component="div">
            {[details.developer?.postalCode, details.developer?.country?.name]
              .filter((el) => el)
              .join(', ')}
          </Typography>
        </div>
      </div>

      <div className="flex justify-between items-center bg-beige border border-grey rounded px-6 py-3 mt-6">
        <Typography variant="subtitle1" component="div">
          6. Project submitted previously to the EISC?
        </Typography>
        <Typography variant="body1" component="div">
          {details.previouslySubmitted ? 'Yes' : 'No'}
        </Typography>
      </div>
      {details.previouslySubmitted && (
        <>
          <div className="flex justify-between items-center bg-beige border border-grey rounded px-6 py-3 mt-2">
            <Typography variant="subtitle1" component="div">
              6.1 Is the developer seeking approval of the extension of the term, renewal or
              amendment of an existing authorization?
            </Typography>
            <Typography variant="body1" component="div">
              {details.existingAuthorization ? 'Yes' : 'No'}
            </Typography>
          </div>
          <div className="flex justify-between items-center bg-beige border border-grey rounded px-6 py-3 mt-2">
            <Typography variant="subtitle1" component="div">
              6.2 Are the activities the same as previously authorized?
            </Typography>
            <Typography variant="body1" component="div">
              {details.samePreviouslyAuthorized ? 'Yes' : 'No'}
            </Typography>
          </div>
          {details.samePreviouslyAuthorized && (
            <div className="flex justify-between items-center bg-beige border border-grey rounded px-6 py-3 mt-2">
              <Typography variant="subtitle1" component="div">
                6.3 Previous EISC File#
              </Typography>
              <Typography variant="body1" component="div">
                {details.previousFileNumber}
              </Typography>
            </div>
          )}
        </>
      )}

      <div className="flex justify-between items-center bg-beige border border-grey rounded px-6 py-3 mt-2">
        <Typography variant="subtitle1" component="div">
          7. Asking for multi-year approval for this submission?
        </Typography>
        <Typography variant="body1" component="div">
          {details.multiYearApproval ? `Yes, ${details.years} years` : 'No'}
        </Typography>
      </div>

      <div className="flex justify-between items-center bg-beige border border-grey rounded px-6 py-3 mt-2">
        <Typography variant="subtitle1" component="div">
          8. The proposed project located on the Yukon North Slope?
        </Typography>
        <Typography variant="body1" component="div">
          {details.locatedOnYukonNorthSlope ? 'Yes' : 'No'}
        </Typography>
      </div>

      {details.locatedOnYukonNorthSlope && (
        <div className="flex justify-between items-center bg-beige border border-grey rounded px-6 py-3 mt-2">
          <Typography variant="subtitle1" component="div">
            8.1 Have contacted the Yukon Environmental and Socio-Economic Assestment Board?
          </Typography>
          <Typography variant="body1" component="div">
            {details.hasContactedYukonBoard ? 'Yes' : 'No'}
          </Typography>
        </div>
      )}

      <div className="mt-6">
        <Typography variant={'subtitle1'}>
          9. The proposed project overlap with other jurisdictions or with other land claim areas?
        </Typography>
        <div className="flex flex-wrap">
          {jurisdictionOptions.map((el, i) => (
            <div className="flex items-center w-1/3" key={i}>
              <Checkbox
                disabled
                checked={details.jurisdictionOverlap?.some((item) => item === String(el.value))}
              />
              <Typography variant={'body1'} component="div">
                {el.label}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      {boards.length > 0 && (
        <div className="mt-6">
          <Typography variant={'subtitle1'}>
            10. The following communities are in the area where the proposed development will take
            place
          </Typography>
          <div className="flex flex-wrap">
            {boards.map((el: Board, i) => (
              <div className="flex items-center w-1/3" key={i}>
                <Checkbox disabled checked={details.boards?.some((item) => item.id === el.id)} />
                <Typography variant={'body1'} component="div">
                  {el.name}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-6">
        <Typography variant={'subtitle1'}>
          11. The proposed development will be located on
        </Typography>
        <div className="flex flex-wrap">
          {landTypeOptions.map((el, i) => (
            <div className="flex items-center w-1/3" key={i}>
              <Checkbox
                disabled
                checked={details.landType?.some((item) => item === String(el.value))}
              />
              <Typography variant={'body1'} component="div">
                {el.label}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-6">
        <Typography variant={'subtitle1'}>
          12. The proposed development will take place on or effect the following areas identified
          in the{' '}
          <MuiLink
            href={process.env.GATSBY_COMMUNITY_CONSERVATION_PLANS_URL}
            color="inherit"
            rel="noopener"
            target="_blank"
          >
            Community Conservation Plans
          </MuiLink>
        </Typography>
        <div className="flex flex-wrap">
          {conservationCategoriesOptions.map((el, i) => (
            <div className="flex items-center w-1/3" key={i}>
              <Checkbox
                disabled
                checked={details.conservationPlanCategory?.some(
                  (item) => item === String(el.value),
                )}
              />
              <Typography variant={'body1'} component="div">
                {el.label}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      {boards.length > 0 && (
        <div className="mt-6">
          <Typography variant={'subtitle1'}>
            13. Community consultations have been undertaken in the following communities
          </Typography>
          <div className="flex flex-wrap">
            {boards.map((el: Board, i) => (
              <div className="flex items-center w-1/3" key={i}>
                <Checkbox
                  disabled
                  checked={details.consultedBoards?.some((item) => item.id === el.id)}
                />
                <Typography variant={'body1'} component="div">
                  {el.name}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="flex justify-between items-center bg-beige border border-grey rounded px-6 py-3 mt-6">
        <Typography variant="subtitle1" component="div">
          14. Letters of support from the community Hunter and Trapper Committees?
        </Typography>
        <Typography variant="body1" component="div">
          {details.huntersAndTrappersLetter ? 'Yes' : 'No'}
        </Typography>
      </div>

      {developmentTypes?.length > 0 && (
        <div className="mt-6">
          <Typography variant={'subtitle1'}>15. Type of Development</Typography>
          <div className="flex flex-wrap">
            {developmentTypes.map((el, i) => (
              <div className="flex items-center w-1/3" key={i}>
                <Checkbox
                  disabled
                  checked={details.developmentTypes?.some((item) => item.id === el.value)}
                />
                <Typography variant={'body1'} component="div">
                  {el.label}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="bg-beige border border-grey rounded px-6 py-3 mt-2">
        <div className="flex justify-between items-center">
          <Typography variant="subtitle1" component="div">
            16. The proposed project is directly sponsored by, conducted by, or under the day-to-day
            control of a government, or government agency or corporation
          </Typography>
          <Typography variant="body1" component="div">
            {details.govermentSponsored ? 'Yes' : 'No'}
          </Typography>
        </div>
        {details.govermentSponsoredDescription && (
          <div className="mt-3">
            <Typography variant="body1">{details.govermentSponsoredDescription}</Typography>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center bg-beige border border-grey rounded px-6 py-3 mt-2">
        <Typography variant="subtitle1" component="div">
          17. This proposed development serve the purposes of government
        </Typography>
        <Typography variant="body1" component="div">
          {details.govermentPurposes ? 'Yes' : 'No'}
        </Typography>
      </div>

      {regulatorsItems?.length > 0 && (
        <div className="mt-6">
          <Typography variant={'subtitle1'}>
            18. A permit or licence is required from one or more of the following regulators or
            agencies
          </Typography>
          <div className="flex flex-wrap">
            {regulatorsItems.map((el, i) => (
              <div className="flex items-center w-1/3" key={i}>
                <Checkbox
                  disabled
                  checked={details.regulators?.some((item) => item.id === el.value)}
                />
                <Typography variant={'body1'} component="div">
                  {el.label}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-6">
        <Typography variant={'subtitle1'}>
          19. The proposed project will involve the following
        </Typography>
        <div className="flex flex-wrap">
          {waterBodiesEnumOptions.map((el, i) => (
            <div className="flex items-center w-1/3" key={i}>
              <Checkbox
                disabled
                checked={details.waterBodies?.some((item) => item === String(el.value))}
              />
              <Typography variant={'body1'} component="div">
                {el.label}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      {mitigatioMeasuresItems?.length > 0 && (
        <div className="mt-6">
          <Typography variant={'subtitle1'}>
            20. The proposed project will implement/use the following mitigation measures
          </Typography>
          <div className="flex flex-wrap">
            {mitigatioMeasuresItems.map((mitigatioMeasuresItem, i) => (
              <div className="flex items-center w-1/3" key={i}>
                <Checkbox
                  disabled
                  checked={details.mitigationMeasures?.some(
                    (item) => item.id === mitigatioMeasuresItem.value,
                  )}
                />
                <Typography variant={'body1'} component="div">
                  {mitigatioMeasuresItem.label}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-6">
        <Typography variant={'subtitle1'}>21. Project Schedule</Typography>
        {details.fieldworkSchedules?.map((fieldWorkSchedule) => (
          <div key={fieldWorkSchedule.id} className="mb-6">
            <div className="flex mt-2">
              <Typography
                variant={'body1'}
                component="div"
                className="flex-100	text-text text-opacity-50"
              >
                Start Date:
              </Typography>
              <Typography variant={'body1'} component="div" className="flex-400">
                {moment(fieldWorkSchedule.startDate).format('YYYY-MM-DD')} (yyyy-mm-dd)
              </Typography>
            </div>
            <div className="flex">
              <Typography
                variant={'body1'}
                component="div"
                className="flex-100	text-text text-opacity-50"
              >
                Work Description:
              </Typography>
              <Typography variant={'body1'} component="div" className="flex-400">
                {fieldWorkSchedule.workDescription}
              </Typography>
            </div>
            <div className="flex">
              <Typography
                variant={'body1'}
                component="div"
                className="flex-100	text-text text-opacity-50"
              >
                Duration in Days:
              </Typography>
              <Typography variant={'body1'} component="div" className="flex-400">
                {`Working days = ${fieldWorkSchedule.duration} per sites`}
              </Typography>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-6">
        <Typography variant={'subtitle1'}>22. List types of equipment or vehicles</Typography>
        <div className="flex mt-2">
          <Typography
            variant={'body1'}
            component="div"
            className="flex-100	text-text text-opacity-50"
          >
            Equipment:
          </Typography>
          <Typography variant={'body1'} component="div" className="flex-400">
            {details.equipment?.join(', ')}
          </Typography>
        </div>
        <div className="flex">
          <Typography
            variant={'body1'}
            component="div"
            className="flex-100	text-text text-opacity-50"
          >
            Vehicle:
          </Typography>
          <Typography variant={'body1'} component="div" className="flex-400"></Typography>
        </div>
      </div>

      <div className="mt-6">
        <Typography variant={'subtitle1'}>23. Additional Information Provided</Typography>
        <RichTextViewer value={details.additionalInfo} />
      </div>
    </div>
  );
};

DetailsOverview.propTypes = {
  details: PropTypes.any.isRequired,
  fieldAudits: PropTypes.any,
  isPrinting: PropTypes.bool,
  labels: PropTypes.any.isRequired,
};

export default DetailsOverview;
