import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@eisc-frontend/shared-ui';

const StatusChangeConfirmationModal = ({
  isModalOpen,
  onCancel,
  onConfirm,
  title,
  body,
  confirmText,
  cancelText,
}) => {
  return (
    <Modal
      isVisible={isModalOpen}
      titleText={title}
      modalText={body}
      cancelText={cancelText}
      onCancel={onCancel}
      confirmText={confirmText}
      onConfirm={onConfirm}
    />
  );
};

StatusChangeConfirmationModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
};

export default StatusChangeConfirmationModal;
