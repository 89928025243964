import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Typography } from '@material-ui/core';

import { RiUserLine as UserIcon } from 'react-icons/ri';

import { RenderDateField } from '@eisc-frontend/shared-ui';

const Deadlines = (props: any) => {
  const { values, validateField, setFieldTouched } = useFormikContext<{
    commentDueAt: string;
    itemDistributedAt: string;
  }>();

  const validateDueDates = () => {
    setFieldTouched('informationRequestDueAt', true);
    validateField('informationRequestDueAt');
  };

  return (
    <div className="pb-6">
      <div className="flex items-center mb-8">
        <UserIcon size={32} className="text-secondary" />
        <Typography variant="h2" className="ml-2 font-semibold" component="div">
          EISC Use
        </Typography>
      </div>
      <div className="flex justify-between bg-beige p-4 border rounded">
        <div className="flex-30">
          <Field
            name="itemDistributedAt"
            autoComplete="off"
            fieldLabel="Project Distributed"
            component={RenderDateField}
            onValueChange={validateDueDates}
          />
        </div>

        <div className="flex-30">
          <Field
            name="informationRequestDueAt"
            autoComplete="off"
            fieldLabel="Deadline for Information Requests"
            component={RenderDateField}
            maxDate={values.commentDueAt}
            onValueChange={validateDueDates}
          />
        </div>

        <div className="flex-30">
          <Field
            name="commentDueAt"
            autoComplete="off"
            fieldLabel="Comment Deadline"
            minDate={values.itemDistributedAt}
            component={RenderDateField}
          />
        </div>
      </div>
    </div>
  );
};

export default Deadlines;
