import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@eisc-frontend/shared-ui';

const UpdateReviewStatusModal = ({ isDialogOpen, onCancel, onConfirm, status }) => {
  return (
    <Modal
      isVisible={isDialogOpen}
      titleText={`${status} Submission`}
      modalText={`Are you ready to mark this submission as ${status}?`}
      cancelText="No"
      onCancel={onCancel}
      confirmText="Yes"
      onConfirm={onConfirm}
    />
  );
};

UpdateReviewStatusModal.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  status: PropTypes.string,
};

export default UpdateReviewStatusModal;
