import { utcToZonedTime } from 'date-fns-tz';
import { fromUnixTime } from 'date-fns';

export default class ReviewInternalDataModel {
  id?: string;
  informationRequestDueAt?: string;
  commentDueAt?: string;
  itemDistributedAt?: string;

  distributionListId?: string;
  distributionUserIds?: { value: string; label: string }[];
  distributionListIds?: { value: string; label: string }[];

  constructor(data: any = {}) {
    this.id = data.id || undefined;

    this.informationRequestDueAt = data.informationRequestDueAt
      ? utcToZonedTime(
          fromUnixTime(data.informationRequestDueAt),
          process.env.GATSBY_DEFAULT_TIMEZONE,
        ).toISOString()
      : undefined;

    this.commentDueAt = data.commentDueAt
      ? utcToZonedTime(
          fromUnixTime(data.commentDueAt),
          process.env.GATSBY_DEFAULT_TIMEZONE,
        ).toISOString()
      : undefined;

    this.itemDistributedAt = data.itemDistributedAt
      ? utcToZonedTime(
          fromUnixTime(data.itemDistributedAt),
          process.env.GATSBY_DEFAULT_TIMEZONE,
        ).toISOString()
      : undefined;

    this.distributionListId = data.distributionList?.id || undefined;
    this.distributionListIds = Array.isArray(data.distributionList?.children)
      ? data.distributionList.children.map((el) => ({ value: el.id, label: el.name }))
      : [];
    this.distributionUserIds = Array.isArray(data.distributionList?.users)
      ? data.distributionList.users.map((el) => ({ value: el.id, label: el.displayName }))
      : [];
  }
}
