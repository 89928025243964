import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { MdExpandLess as ExpandLessIcon, MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import {
  RiFileLine as FileIcon,
  RiDownload2Line as DownloadIcon,
  RiLinksLine as LinkIcon,
} from 'react-icons/ri';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#F8F4F4',
    padding: '4px',
  },
  fileIcon: {
    color: theme.palette.text.primary,
  },
  fileText: {
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    padding: 0,
    margin: '5px',
  },
  fileSize: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    padding: 0,
    whiteSpace: 'nowrap',
    margin: '5px',
  },
  collapseButton: {
    color: theme.palette.text.primary,
  },
  caption: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
  },
  download: {
    backgroundColor: '#5F673B1A',
    color: theme.palette.text.primary,
    paddingTop: 0,
    paddingBottom: 0,
  },
  linkIcon: {
    color: theme.palette.common.white,
  },
  linkButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
    padding: '9px',
    width: '34px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
    },
  },
}));

const AttachmentsList = (props) => {
  const classes = useStyles();
  const { commentSets, attachmentsSize, refs, labels, toggleExpanded } = props;
  const [isAttachmentSectionOpen, setAttachmentsOpen] = useState(true);

  const displayHeader = (commentSet) => {
    return commentSet.comments.some((comment) => comment.attachments.length > 0);
  };

  const scrollToFile = (commentSetId, fileId) => {
    toggleExpanded(commentSetId, true);
    refs[fileId]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Box className="rounded-t-md border">
      <Box
        display="flex"
        className={clsx('flex justify-between items-center border-b', classes.header)}
      >
        <Typography variant="caption" className="my-1 ml-1">
          {labels.attachmentsListPrefix} {attachmentsSize}{' '}
          {attachmentsSize > 1 ? 'Attachments' : 'Attachment'}
        </Typography>
        <IconButton
          className={classes.collapseButton}
          onClick={() => setAttachmentsOpen(!isAttachmentSectionOpen)}
        >
          {isAttachmentSectionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse in={isAttachmentSectionOpen}>
        {commentSets.map((commentSet) => {
          return (
            <React.Fragment key={commentSet.id}>
              {displayHeader(commentSet) && (
                <Box className="px-5 pb-7">
                  <Box mt={3} className={classes.caption}>
                    {`${commentSet.organization.name}: ${commentSet.author.displayName}`}
                  </Box>
                  <Box my={1}>
                    {commentSet.comments.map((comment) => {
                      return (
                        <Box key={comment.id}>
                          {comment.attachments.map((file) => {
                            return (
                              <Box
                                className="flex justify-start space-x-2 my-2 rounded"
                                key={file.id}
                              >
                                <Button
                                  className={classes.download}
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  startIcon={<FileIcon className={classes.fileIcon} />}
                                  endIcon={<DownloadIcon />}
                                  onClick={() => scrollToFile(commentSet.id, file.id)}
                                >
                                  <Typography className={classes.fileText} variant="body1">
                                    {`${file.name}`}
                                  </Typography>
                                </Button>
                                <IconButton className={classes.linkButton}>
                                  <LinkIcon size={16} className={classes.linkIcon} />
                                </IconButton>
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </Collapse>
    </Box>
  );
};

AttachmentsList.propTypes = {
  commentSets: PropTypes.array.isRequired,
  attachmentsSize: PropTypes.any.isRequired,
  refs: PropTypes.any,
  labels: PropTypes.any.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};

export default AttachmentsList;
