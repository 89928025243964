import React, { useEffect, useRef } from 'react';
import DetailsOverview from '../ReviewDetails/DetailsOverview';
import DetailsDocuments from '../ReviewDetails/DetailsDocuments/DetailsDocuments';
import DetailsLocation from '../ReviewDetails/DetailsLocation';
import DetailsComments from '../ReviewDetails/DetailsComments/DetailsComments';
import html2canvas from 'html2canvas';

type PdfPrintContentProps = {
  details: any;
  labels?: any;
  isPrinting?: any;
  mapImage?: string;
};

const PdfPrintContent = (props: PdfPrintContentProps) => {
  const { details, labels, isPrinting, mapImage } = props;
  const locationInformationRef = useRef(null);

  return (
    <div className="printItem" id="printContainer">
      {/** Project Details Overview */}
      <DetailsOverview details={details} labels={labels} isPrinting={isPrinting} />
      {/** Documents */}
      <DetailsDocuments
        attachments={details.attachments}
        labels={labels}
        isPrinting={isPrinting}
        reviewId={details.id}
      />
      {/** Location */}
      <div id="locationInformation" ref={locationInformationRef}>
        <DetailsLocation
          mapImage={mapImage}
          isPrinting={isPrinting}
          location={details.location}
          commonName={details.commonName}
          ntsMapSheet={details.ntsMapSheet}
        />
      </div>
      {/** Comments */}
      <DetailsComments
        developerId={details.developer?.id}
        reviewId={details.id}
        labels={labels}
        isPrinting={isPrinting}
      />
    </div>
  );
};

export default PdfPrintContent;
