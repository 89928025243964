import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import { useReactToPrint } from 'react-to-print';
import { navigate } from 'gatsby';
import moment from 'moment';
import { differenceInDays, startOfDay, fromUnixTime } from 'date-fns';
import { clone } from 'lodash';

import {
  makeStyles,
  Select,
  Box,
  Hidden,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Button,
  Typography,
  LinearProgress,
  Link,
  Card,
} from '@material-ui/core';

import { MdKeyboardArrowLeft as KeyboardArrowLeftIcon } from 'react-icons/md';

import {
  RiMailSendLine as MailIcon,
  RiEditLine as EditIcon,
  RiPrinterLine as PrintIcon,
  RiLightbulbLine as LightbulbLineIcon,
  RiProfileLine as RiProfileLineIcon,
  RiMapPinLine as RiMapPinLineIcon,
  RiFileTextLine as FileTextIcon,
  RiQuestionAnswerLine as CommentsIcon,
  RiAdminLine as RiAdminLineIcon,
  RiChatCheckFill as RiChatCheckFillIcon,
} from 'react-icons/ri';

import {
  Map,
  Utils,
  ReviewItemLabels,
  ReviewStatusEnum,
  AllowedStatusTransitions,
  StatusWithNotification,
} from '@eisc-frontend/shared-ui';

import '../../style/page.scss';
import { useReviews, useGetUsers, useUpdateReviewStatus, useUpdateReview } from '../../adapters';
import { MenuItems, EISCUseSectionStatus } from '../../constants';
import StaffSection from '../../components/ReviewDetails/StaffSection/StaffSection';
import DetailsOverview from '../../components/ReviewDetails/DetailsOverview';
import DetailsDocuments from '../../components/ReviewDetails/DetailsDocuments/DetailsDocuments';
import DetailsLocation from '../../components/ReviewDetails/DetailsLocation';
import DetailsComments from '../../components/ReviewDetails/DetailsComments/DetailsComments';
import UpdateReviewStatusModal from '../../components/ReviewDetails/UpdateReviewStatusModal';
import { GlobalContext } from '../../contexts/GlobalContext';
import ResponseException from '../../models/ResponseException';
import Page from '../../components/page';
import UploadDocumentsDialog from '../../components/UploadDocumentsDialog';
import { isStaffUser, isProponentUser, isLoggedIn, isStakeholderUser } from '../../contexts/auth';
import { OrsRoutes } from '../../routes';
import StatusChangeConfirmationModal from '../../components/StatusChangeConfirmationModal';
import { utcToZonedTime } from 'date-fns-tz';
import { toEISCTime } from '../../utils/functions';
import PdfPrintContent from '../../components/PdfPrintContent/PdfPrintContent';

const isStaff = isStaffUser();
const labels = ReviewItemLabels;
const loggedIn = isLoggedIn();

const useStyles = makeStyles(() => ({
  subtitle: {
    opacity: 0.5,
  },
  map: {
    width: '100%',
    height: '150px',
  },
}));

const ReviewDetailsPage = ({ params }) => {
  const globalContext = useContext(GlobalContext);
  const classes = useStyles();
  const pdfPrintContentRef = useRef(null);

  let screenshotLocationMap;
  const { isFetchingReviews, getReview } = useReviews();
  const { isFetching: isFetchingUsers, getUsers } = useGetUsers();
  const {
    success: updateStatusSuccess,
    updateReviewStatus,
    errors: statusErrors,
  } = useUpdateReviewStatus();
  const { errors: updateReviewErrors } = useUpdateReview();

  const [details, setDetails] = useState(null);
  const [fieldAudits, setFieldAudits] = useState(null);
  const [reviewErrors, setReviewErrors] = useState<string[]>([]);
  const [isProponent, setProponent] = useState(false);
  const [isStakeholder, setIsStakeholder] = useState(false);
  const [hasStakeholder, setHasStakeholder] = useState(false);
  const [menuItem, setMenuItem] = useState('#overview');
  const [statusOptions, setStatusOptions] = useState([]);
  const [locationPoint, setLocationPoint] = useState(null);
  const [irPeriodOpen, setIrPeriodOpen] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState({ open: false, status: '' });
  const [uploadDocumentDialogOpen, setUploadDocumentDialogOpen] = useState(false);
  const [statusChangeErrors, setStatusChangeErrors] = useState<string[]>([]);
  const [showStatusAlertModal, setShowStatusAlertModal] = useState(false);
  const [mapImageContent, setMapImageContent] = useState(null);
  const [statusAlertModal, setStatusAlertModal] = useState({
    title: '',
    body: '',
    confirmText: '',
    cancelText: '',
    status: '',
  });
  const [allowSubmitResponses, setAllowSubmitResponses] = useState(false);
  const [allowSubmitComments, setAllowSubmitComments] = useState(false);
  const [allowSubmitInformationRequests, setAllowSubmitInformationRequests] = useState(false);
  const [allowRespondInformationRequests, setAllowRespondInformationRequests] = useState(false);

  const handlePrint = useReactToPrint({
    onPrintError: (loc, err) => {
      console.log('print error');
      console.log(loc, err);
    },
    removeAfterPrint: true,
    onBeforeGetContent: async () => {
      return performScreenshot();
    },
    content: () => pdfPrintContentRef.current,
    documentTitle: 'Review Item',
  });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const published = urlSearchParams.get('published');
    if (published === 'comments') {
      globalContext.showMessage({
        title: 'Your comments were submitted successfully!',
        variant: 'success',
        body: 'Thank you for adding your voice to this review item. We will inform you if/when the proponent of this submission item responds to your comments and recommendations.',
      });
      window.history.replaceState({}, document.title, `/submission/${params.id}`);
    } else if (published === 'responses') {
      globalContext.showMessage({
        title: 'Your responses were submitted successfully!',
        variant: 'success',
        body: 'Thank you for adding your voice to this review item. We will inform you if there are further comments and recommendations and when the submission item is closed.',
      });
      window.history.replaceState({}, document.title, `/submission/${params.id}`);
    }
  }, []);

  const getAlertMessage = () => {
    switch (details.status) {
      case ReviewStatusEnum.Closed:
        return 'This screening is complete.';

      case ReviewStatusEnum.ClosedForComments:
        return 'This project is closed for comments.';

      case ReviewStatusEnum.AwaitingComments:
        return (
          <>
            <Typography variant="body1" component="div">
              {labels.infoBox1}
            </Typography>
            <Typography variant="body1" component="div">
              {labels.infoBox2}{' '}
              {moment
                .unix(details.commentDueAt)
                .startOf('day')
                .diff(moment().startOf('day'), 'days')}{' '}
              days on {toEISCTime(details.commentDueAt, 'MMMM dd, yyyy')}.
            </Typography>
          </>
        );

      default:
        return null;
    }
  };

  const refs = MenuItems.reduce((item, value) => {
    item[value.value] = React.createRef();
    return item;
  }, {});

  const handleScroll = (menuItem) => {
    refs[menuItem].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const onStatusChange = (status) => {
    setShowStatusAlertModal(false);
    if (StatusWithNotification.includes(status)) {
      navigate(
        OrsRoutes.SendNotifications.replace(':status', status).replace(':reviewId', params.id),
      );
    } else {
      setChangeStatusModal({ open: true, status });
    }
  };

  const handleStatusChange = async (e) => {
    const status = e?.target?.value.toString();
    const dueDate = startOfDay(fromUnixTime(details.commentDueAt));
    const today = startOfDay(new Date());
    const difference = differenceInDays(dueDate, today);
    setStatusChangeErrors([]);

    if (status === ReviewStatusEnum.AwaitingComments) {
      if (details.status === ReviewStatusEnum.ClosedForComments && difference < 0) {
        // Changing status from 'Closed for Comments' to 'Awaiting Comments' and due date is in the past
        setStatusChangeErrors([
          `The comment deadline must be updated to a future date in order to change the submission status to "${status}"`,
        ]);
        return;
      } else if (!details.commentDueAt || difference < 0) {
        setStatusChangeErrors([
          `A comment deadline must be set/updated to a future date before updating status to "${status}"`,
        ]);
        return;
      } else if (difference >= 0 && difference < 44) {
        setStatusAlertModal({
          title: 'The “Comment Deadline” date is less than the 45 days commenting period',
          body: `The comment deadline is currently ${difference} day${
            difference !== 2 ? 's' : ''
          } from today.  Would you like to go back and modify this deadline before proceeding?`,
          confirmText: 'Continue with current deadline',
          cancelText: 'Cancel',
          status: status,
        });
        setShowStatusAlertModal(true);
        return;
      } else if (difference > 45) {
        setStatusAlertModal({
          title: 'The “Comment Deadline” date is greater than the 45 days commenting period',
          body: `The comment deadline is currently ${difference} days from today. Would you like to go back and modify this deadline before proceeding?`,
          confirmText: 'Continue with current deadline',
          cancelText: 'Cancel',
          status: status,
        });
        setShowStatusAlertModal(true);
        return;
      }
    } else if (status === ReviewStatusEnum.ClosedForComments) {
      if (difference >= 0) {
        setStatusAlertModal({
          title: 'Comment Deadline Adjustment',
          body: `Due to the submission status changing to “Closed for Comments”, the “Comment Deadline” field will be updated to yesterday’s date.`,
          confirmText: 'Continue',
          cancelText: 'Cancel',
          status: status,
        });
        setShowStatusAlertModal(true);
        return;
      }
    }

    onStatusChange(status);
  };

  const changeReviewStatus = async () => {
    await updateReviewStatus(details.id, changeStatusModal.status);
  };

  const onEditReviewClick = () => {
    window.open(OrsRoutes.EditReview.replace(':reviewId', params.id), '_self');
  };

  const parseLocation = () => {
    const location =
      details && Utils.isJsonString(details.location) ? JSON.parse(details.location) : null;
    if (location && location.latitude && location.longitude) {
      return {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      };
    }
    return null;
  };

  useEffect(() => {
    const errors = [...reviewErrors, ...statusErrors, ...statusChangeErrors, ...updateReviewErrors];
    if (errors.length > 0) {
      globalContext.showMessage({
        title: 'Error',
        variant: 'error',
        body: errors,
      });
    }
  }, [reviewErrors, statusErrors, statusChangeErrors, updateReviewErrors]);

  useEffect(() => {
    if (updateStatusSuccess) {
      setChangeStatusModal({ open: false, status: '' });
      window.location.reload();
    }
  }, [updateStatusSuccess]);

  const sendNotification = () => {
    navigate(OrsRoutes.SendNotifications.replace(':status', 'Any').replace(':reviewId', params.id));
  };

  const performScreenshot = async () => {
    const mapImg = await screenshotLocationMap();
    setMapImageContent(mapImg);

    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  useEffect(() => {
    (async () => {
      try {
        const reviewResponse = await getReview(params.id);
        if (reviewResponse?.data?.review) {
          const { developer, distributionList } = reviewResponse?.data.review;

          if (loggedIn) {
            const users = await getUsers(developer?.id);

            const isProponent = await isProponentUser(users);
            setProponent(isProponent);

            const isStakeholder = await isStakeholderUser(distributionList?.allUsers || []);
            setIsStakeholder(isStakeholder);

            setHasStakeholder(distributionList?.allUsers?.length > 0);
          }

          setDetails(reviewResponse?.data.review);
        }

        if (reviewResponse?.data?.fieldAudit) {
          setFieldAudits(clone(reviewResponse?.data.fieldAudit));
        }
      } catch (e) {
        setReviewErrors(new ResponseException(e).getErrorMessage());
      }
    })();
  }, []);

  useEffect(() => {
    if (details) {
      const { informationRequestDueAt } = details;

      if (informationRequestDueAt) {
        const IRTimezoneDate = utcToZonedTime(
          fromUnixTime(informationRequestDueAt),
          process.env.GATSBY_DEFAULT_TIMEZONE,
        );
        setIrPeriodOpen(
          moment(IRTimezoneDate)
            .startOf('day')
            .diff(moment().startOf('day').format('YYYY-MM-DD'), 'days') >= 0,
        );
      }

      setLocationPoint(parseLocation());

      if (AllowedStatusTransitions[details.status]) {
        const defaultOption = { value: details.status, label: `Status: ${details.status}` };
        setStatusOptions([defaultOption, ...AllowedStatusTransitions[details.status]]);
      }
    }
  }, [details]);

  const uploadAttachmentsCallback = (attachments) => {
    if (attachments.length) {
      details.attachments = [...details.attachments, ...attachments];
    }
    setUploadDocumentDialogOpen(false);
  };

  const iconPicker = (menuItem: string) => {
    switch (menuItem) {
      case '#staff':
        return <RiAdminLineIcon size="1.5rem" color="#000" />;
      case '#overview':
        return <RiProfileLineIcon size="1.5rem" color="#000" />;
      case '#documents':
        return <FileTextIcon size="1.5rem" color="#000" />;
      case '#location':
        return <RiMapPinLineIcon size="1.5rem" color="#000" />;
      case '#comments':
        return <CommentsIcon size="1.5rem" color="#000" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (details) {
      setAllowSubmitComments(
        hasStakeholder &&
          (isStaff || (isStakeholder && details.status === ReviewStatusEnum.AwaitingComments)),
      );

      setAllowSubmitResponses(
        hasStakeholder &&
          (isStaff || (isProponent && details.status === ReviewStatusEnum.AwaitingComments)),
      );

      setAllowSubmitInformationRequests(
        hasStakeholder &&
          (isStaff ||
            (irPeriodOpen &&
              isStakeholder &&
              details.status === ReviewStatusEnum.AwaitingComments)),
      );

      setAllowRespondInformationRequests(
        hasStakeholder &&
          (isStaff || (isStakeholder && details.status === ReviewStatusEnum.AwaitingComments)),
      );
    }
  }, [isProponent, irPeriodOpen, details, isStakeholder, hasStakeholder]);

  return (
    <Page isFetching={false}>
      <Box px={2}>
        {/** Title section */}
        <Box py={3}>
          <Button onClick={() => navigate('/submissions')} startIcon={<KeyboardArrowLeftIcon />}>
            {labels.breadcrumbs}
          </Button>

          {/** Loader */}
          {(isFetchingReviews || isFetchingUsers) && <LinearProgress />}

          {/** Review Details */}
          {details && (
            <Box mt={2}>
              <Typography data-test={'review-details-title'} variant="h2">
                {details.title}
              </Typography>
              <Typography data-test={'review-details-name'} variant="body1">
                {details.developer?.name}
              </Typography>
              <Box my={1}>
                {details.board && (
                  <Typography variant="body2" className={classes.subtitle}>
                    {`${labels.boardPrefix} ${details.board.title} ${details.board.abbreviation}`}
                  </Typography>
                )}
              </Box>

              <div className="flex items-center border-1 rounded border-secondary p-2 mt-2 bg-secondaryFaded">
                <LightbulbLineIcon size="2rem" className="text-secondary" />
                <Typography className="ml-2 flex-1" variant="body1" component="div">
                  By submitting comments and recommendations to the online registry, you and/or your
                  organization indicate a preference to become party to this Proceeding and, as
                  such, are expected to follow the Committees{' '}
                  <Link
                    href={process.env.GATSBY_BOARDS_RULES_OF_PROCEDURE_URL}
                    color="inherit"
                    rel="noopener"
                    target="_blank"
                  >
                    {`Rules of Procedure.`}
                  </Link>
                </Typography>
              </div>

              <Box mt={2}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={3} md={3}>
                    <Box className="sticky top-5">
                      <Paper>
                        <MenuList>
                          <Typography className="text-secondary uppercase font-bold ml-4 mt-2">
                            Review Section
                          </Typography>
                          {MenuItems.map((item) => {
                            return item.value === '#staff' && !isStaff ? null : (
                              <MenuItem
                                key={item.value}
                                className={
                                  menuItem === item.value ? 'bg-secondaryFaded rounded' : ''
                                }
                                onClick={() => {
                                  setMenuItem(item.value);
                                  handleScroll(item.value);
                                }}
                              >
                                <Hidden smDown>
                                  <ListItemIcon className="min-w-0 mr-2">
                                    {iconPicker(item.value)}
                                  </ListItemIcon>
                                </Hidden>
                                <ListItemText
                                  primary={item.label}
                                  classes={{ primary: 'font-bold ml-1' }}
                                />
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </Paper>
                      <Box className="my-1.5">
                        <Button
                          variant="outlined"
                          size="large"
                          onClick={handlePrint}
                          className="hover:bg-white w-full py-4 px-8 flex justify-between"
                          endIcon={<PrintIcon size="1.5rem" />}
                        >
                          {labels.print}
                        </Button>
                      </Box>
                      {loggedIn && (
                        <>
                          {details.status !== ReviewStatusEnum.Closed &&
                            (isProponent || isStaff) && (
                              <Box className="my-1.5">
                                <Button
                                  variant="outlined"
                                  size="large"
                                  className="hover:bg-white w-full my-1.5 py-4 px-8 flex justify-between"
                                  onClick={onEditReviewClick}
                                  endIcon={<EditIcon size="1.5rem" />}
                                >
                                  {labels.editItem}
                                </Button>
                              </Box>
                            )}
                        </>
                      )}
                      {isStaff && (
                        <>
                          <Box className="my-1.5">
                            <Button
                              className="hover:bg-white w-full py-4 px-8 flex justify-between"
                              variant="outlined"
                              size="large"
                              onClick={sendNotification}
                              endIcon={<MailIcon size="1.5rem" />}
                            >
                              Send Notification
                            </Button>
                          </Box>

                          <Box my={1.5}>
                            <Select
                              name="status"
                              className={'w-full'}
                              classes={{ root: 'w-full py-3 rounded' }}
                              value={details.status}
                              onChange={(e) => handleStatusChange(e)}
                              disabled={statusOptions.length <= 1}
                            >
                              {statusOptions.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </>
                      )}

                      {locationPoint && (
                        <Box className="my-1.5">
                          <Paper>
                            <Typography variant="body2">Location</Typography>
                            <Box mt={1} className={classes.map}>
                              <Map coordinates={locationPoint} zoom={5} />
                            </Box>
                          </Paper>
                        </Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={9}>
                    {getAlertMessage() && (
                      <div className="flex items-center border-1 rounded border-primary p-2 mt-2 bg-primaryFaded">
                        <RiChatCheckFillIcon size="2rem" className="text-primary" />
                        <Typography className="ml-2 flex-1" variant="body1" component="div">
                          {getAlertMessage()}
                        </Typography>
                      </div>
                    )}
                    <Box my={2}>
                      {/* Staff Section */}
                      {isStaff &&
                        (EISCUseSectionStatus.includes(details?.status) ||
                          details.sentNotifications) && (
                          <Card ref={refs[MenuItems[0].value]} className="pt-8 pb-6 px-5">
                            <StaffSection details={details} />
                          </Card>
                        )}

                      <Paper>
                        <Box mx={4} my={2}>
                          {/** Overview */}
                          <Typography ref={refs[MenuItems[1].value]} />
                          <DetailsOverview
                            fieldAudits={fieldAudits}
                            details={details}
                            labels={labels}
                          />
                        </Box>
                      </Paper>
                      {/** Documents */}
                      <Card ref={refs[MenuItems[2].value]} className="pt-8 pb-6 px-5">
                        <DetailsDocuments
                          isProponent={isProponent}
                          attachments={details.attachments}
                          labels={labels}
                          setUploadDialogOpen={setUploadDocumentDialogOpen}
                          isPrinting={false}
                          reviewId={details.id}
                        />
                      </Card>
                      {/** Location */}
                      <Card ref={refs[MenuItems[3].value]} className="pt-8 pb-6 px-5 my-6">
                        <DetailsLocation
                          onLoad={({ screenshotLocation }) => {
                            screenshotLocationMap = screenshotLocation;
                          }}
                          location={details.location}
                          commonName={details.commonName}
                          ntsMapSheet={details.ntsMapSheet}
                        />
                      </Card>
                    </Box>
                    {/** Comments */}
                    <Card ref={refs[MenuItems[4].value]} className="py-8 px-5">
                      <DetailsComments
                        status={details.status}
                        developerId={details.developer?.id}
                        reviewId={details.id}
                        commentDueAt={details.commentDueAt}
                        labels={labels}
                        reviewType={details.type}
                        allowSubmitComments={allowSubmitComments}
                        allowSubmitResponses={allowSubmitResponses}
                        allowSubmitInformationRequests={allowSubmitInformationRequests}
                        allowRespondInformationRequests={allowRespondInformationRequests}
                      />
                      <div id="content-to-print" ref={pdfPrintContentRef}>
                        <PdfPrintContent
                          mapImage={mapImageContent}
                          details={details}
                          labels={labels}
                          isPrinting={true}
                        />
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}

          {/** Modals */}
          <UpdateReviewStatusModal
            isDialogOpen={changeStatusModal.open}
            status={changeStatusModal.status}
            onCancel={() => setChangeStatusModal({ open: false, status: '' })}
            onConfirm={changeReviewStatus}
          />

          <UploadDocumentsDialog
            isDialogOpen={uploadDocumentDialogOpen}
            onCloseDialog={(attachments) => uploadAttachmentsCallback(attachments)}
            reviewId={details?.id}
          />

          <StatusChangeConfirmationModal
            isModalOpen={showStatusAlertModal}
            onCancel={() => setShowStatusAlertModal(false)}
            onConfirm={() => onStatusChange(statusAlertModal.status)}
            title={statusAlertModal.title}
            body={statusAlertModal.body}
            confirmText={statusAlertModal.confirmText}
            cancelText={statusAlertModal.cancelText}
          />
        </Box>
      </Box>
    </Page>
  );
};

ReviewDetailsPage.propTypes = {
  params: PropTypes.any,
};

export default ReviewDetailsPage;
