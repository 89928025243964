import React from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { MdExpandMore as ExpandMoreIcon, MdInfoOutline as MdInfoOutlineIcon } from 'react-icons/md';

import { RiDownload2Line as DownloadIcon, RiFileLine as FileIcon } from 'react-icons/ri';

import { Utils, RichTextViewer, CommentType } from '@eisc-frontend/shared-ui';
import ResponseSection from './ResponseSection/ResponseSection';
import { CommentStatusEnum } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  dense: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  accordion: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px 4px 0 0',
    fontSize: '18px',
    lineHeight: '48px',
    color: theme.palette.common.white,
    boxShadow: 'none',
    maxHeight: '48px',
  },
  list: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderTop: 0,
    padding: 0,
  },
  listIndex: {
    opacity: 0.4,
    fontSize: '16px',
    fontWeight: 600,
    alignSelf: 'flex-start',
    paddingTop: '10px',
  },
  attachmentBtn: {
    cursor: 'initial',
  },
  transcriptBtn: {
    cursor: 'pointer',
  },
  fileIcon: {
    color: theme.palette.text.primary,
  },
  downloadIcon: {
    cursor: 'pointer',
  },
  fileText: {
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '200px',
    padding: 0,
    margin: '5px',
  },
  fileSize: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    padding: 0,
    whiteSpace: 'nowrap',
    margin: '5px',
  },
  date: {
    opacity: 0.4,
    textAlign: 'right',
  },
  infoIcon: {
    color: '#fff',
    marginRight: 4,
  },
  lateLabel: {
    color: '#fff',
    paddingBottom: 0,
  },
  download: {
    backgroundColor: '#5F673B1A',
    color: theme.palette.text.primary,
    fontWeight: 'unset',
  },
}));

const PublicComments = (props) => {
  const classes = useStyles();
  const { commentSets, isPrinting, refs, labels, isStaff, reviewId, expanded, toggleExpanded } =
    props;

  const hasTopic = (comment) => {
    return comment.topic || (comment.customTopic && comment.customTopic !== 'No Topic');
  };

  const displayResponses = (comment) => {
    return comment.response && comment.response.status === CommentStatusEnum.PUBLISHED;
  };

  const getCreatedDate = (comment) => {
    return moment.unix(comment.updatedAt).format('LL');
  };

  const downloadFile = (file: any) => {
    window.open(file.generatedUrl, '_blank');
  };

  const editComments = (event, commentSet) => {
    event.stopPropagation();

    navigate(
      `${process.env.GATSBY_ORS_BASE_URL}/submission/${reviewId}/manage-comments/developer/${commentSet.organization.id}/author/${commentSet.author.id}/${commentSet.type}`,
    );
  };

  const editedByComment = (comment, commentSet) => {
    if (!commentSet.publishedAt) return false;
    return moment(commentSet.publishedAt).diff(moment(comment.updatedAt)) !== 0;
  };

  const getAttachments = (item) => {
    return (
      <React.Fragment>
        {item?.attachments?.map((file) => {
          return (
            <React.Fragment key={file.id}>
              <Grid item xs={3} />
              <Grid item xs={7}>
                <Box id={file.id} className="flex justify-start space-x-2 my-2 rounded">
                  <Button
                    className={classes.download}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<FileIcon className={classes.fileIcon} />}
                    endIcon={<DownloadIcon />}
                    onClick={() => downloadFile(file.file)}
                    ref={refs[file.id]}
                  >
                    {file.name}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={2} />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <Box>
      <Box my={2}>
        {commentSets.map((commentSet) => {
          const isExpanded = expanded?.indexOf(commentSet.id) >= 0 || isPrinting || false;

          return (
            <Accordion
              expanded={isExpanded}
              onChange={(_, isExpanded) => toggleExpanded(commentSet.id, isExpanded)}
              key={commentSet.id}
              style={{ boxShadow: 'none' }}
              className="printSection mb-2.5"
            >
              <AccordionSummary
                className={classes.accordion}
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
              >
                <Box className="flex justify-between items-center flex-1">
                  <Box className="justify-center flex flex-col">
                    <Typography className="p-0 text-white font-semibold">
                      {`${commentSet.organization.name}: ${commentSet.author.displayName}`}
                    </Typography>
                    <Typography variant="body2" className="text-white p-0">
                      {commentSet.type === CommentType.Comment ? 'Comment' : 'Information Request'}
                    </Typography>
                  </Box>

                  <Box display="flex">
                    {commentSet.late && (
                      <Box display="flex" alignItems="center" mr={2}>
                        <MdInfoOutlineIcon className={classes.infoIcon} />
                        <Typography variant="body2" className="pb-0 text-white">
                          Submitted after commenting period
                        </Typography>
                      </Box>
                    )}
                    {isStaff && (
                      <Box className="flex items-center">
                        <Button
                          variant="outlined"
                          onClick={(event) => editComments(event, commentSet)}
                          className="bg-white border-white"
                        >
                          Edit
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </AccordionSummary>

              {isExpanded && (
                <AccordionDetails className={classes.list}>
                  <List className={classes.dense}>
                    {commentSet.comments.map((comment, index) => {
                      return (
                        <React.Fragment key={index}>
                          <ListItem className="w-full">
                            <Grid container>
                              <Grid item xs={1}>
                                <ListItemIcon className={classes.listIndex}>
                                  {index < 9 ? `0${index + 1}` : `${index + 1}`}
                                </ListItemIcon>
                              </Grid>
                              <Grid item xs={11}>
                                <Box className="p-4 w-full">
                                  {hasTopic(comment) && (
                                    <React.Fragment>
                                      <Grid container>
                                        <Grid item xs={3}>
                                          <Typography variant="subtitle2">Topic</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                          <Typography variant="body1">
                                            {comment.topic
                                              ? comment.topic.name
                                              : comment.customTopic}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Typography variant="body1" className={classes.date}>
                                            {getCreatedDate(comment)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </React.Fragment>
                                  )}
                                  {comment.addressTo && (
                                    <React.Fragment>
                                      <Grid container>
                                        <Grid item xs={3}>
                                          <Typography variant="subtitle2">Addressed To</Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                          <Typography variant="body1">
                                            {comment.addressTo.name}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </React.Fragment>
                                  )}
                                  {!Utils.isRichTextEmpty(comment.description) && (
                                    <React.Fragment>
                                      <Grid container>
                                        <Grid item xs={3}>
                                          <Typography variant="subtitle2">
                                            {labels.comment}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                          <div className="-mt-3">
                                            <RichTextViewer value={comment.description} />
                                          </div>
                                        </Grid>
                                        {hasTopic(comment) ? (
                                          <Grid item xs={2} />
                                        ) : (
                                          <Grid item xs={2}>
                                            <Typography variant="body1" className={classes.date}>
                                              {getCreatedDate(comment)}
                                            </Typography>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </React.Fragment>
                                  )}
                                  {!Utils.isRichTextEmpty(comment.recommendation) && (
                                    <React.Fragment>
                                      <Grid container>
                                        <Grid item xs={3}>
                                          <Typography variant="subtitle2">
                                            {labels.recommendation}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                          <div className="-mt-3">
                                            <RichTextViewer value={comment.recommendation} />
                                          </div>
                                        </Grid>
                                        <Grid item xs={2} />
                                      </Grid>
                                    </React.Fragment>
                                  )}

                                  {displayResponses(comment) && (
                                    <ResponseSection
                                      refs={refs}
                                      response={comment.response}
                                      commentSet={commentSet}
                                      commentRow={comment}
                                    />
                                  )}

                                  {getAttachments(comment)}

                                  {editedByComment(comment, commentSet) && (
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Typography variant="body1" className={classes.date}>
                                          This comment was edited by board staff
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                  </List>
                </AccordionDetails>
              )}
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

PublicComments.defaultProps = {
  isPrinting: false,
};

PublicComments.propTypes = {
  commentSets: PropTypes.array.isRequired,
  isPrinting: PropTypes.bool,
  refs: PropTypes.any,
  labels: PropTypes.any.isRequired,
  isIR: PropTypes.bool.isRequired,
  isStaff: PropTypes.bool.isRequired,
  reviewId: PropTypes.string.isRequired,
  expanded: PropTypes.array.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};

export default PublicComments;
