import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MdDescription as DescriptionIcon, MdGetApp as GetAppIcon } from 'react-icons/md';

import { Utils } from '@eisc-frontend/shared-ui';
import { CommentStatusEnum } from '../../../../../constants';

const useStyles = makeStyles((theme) => ({
  attachmentBtn: {
    cursor: 'initial',
  },
  fileIcon: {
    color: '#E67337',
  },
  downloadIcon: {
    cursor: 'pointer',
  },
  editor: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: '#fff',
    borderRadius: '4px',
    verticalAlign: 'top',
    width: '100%',
    height: '245px',
    display: 'flex',
    padding: 0,
    whiteSpace: 'break-spaces',
  },
  attachmentsBox: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  formButton: {
    maxHeight: '35px',
    minWidth: '140px',
    boxShadow: 'none',
  },
  commentAuthorAvatar: {
    width: '32px',
    height: '32px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#009B58',
  },
  responseAuthorAvatar: {
    width: '32px',
    height: '32px',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: '#05121A',
  },
  userDetails: {
    opacity: 0.4,
  },
  accountIcon: {
    fontSize: '14px',
    marginRight: '4px',
    marginLeft: '4px',
  },
  viewButton: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}));

const ResponseSection = (props) => {
  const { response, refs } = props;

  const classes = useStyles();

  const getDescription = (res) => {
    if (res?.status === CommentStatusEnum.DRAFT || !res?.description) {
      return null;
    }
    return Utils.richTextContentToPlainText(res.description);
  };

  const downloadFile = (file: any) => {
    window.open(file.generatedUrl, '_blank');
  };

  const getAttachments = (item) => {
    return (
      <React.Fragment>
        {item.attachments?.map((file) => {
          return (
            <React.Fragment key={file.id}>
              <Grid item xs={3} />
              <Grid item xs={7}>
                <Box id={file.id} my={0.5}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    ref={refs[file.id]}
                    className={classes.attachmentBtn}
                    startIcon={<DescriptionIcon className={classes.fileIcon} />}
                    onClick={() => downloadFile(file.file)}
                    endIcon={<GetAppIcon className={classes.downloadIcon} />}
                  >
                    {file.name}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={2} />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Grid item xs={3}>
        <Typography variant="subtitle2">Response(s)</Typography>
      </Grid>
      <Grid item xs={7}>
        <Box mb={1}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: getDescription(response),
            }}
          />

          {getAttachments(response)}
        </Box>
      </Grid>
      <Grid item xs={2} />
    </React.Fragment>
  );
};

ResponseSection.propTypes = {
  response: PropTypes.any.isRequired,
  commentSet: PropTypes.any,
  commentRow: PropTypes.any,
  refs: PropTypes.any,
};

export default ResponseSection;
