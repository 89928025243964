import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { navigate } from 'gatsby';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  draftBox: {
    backgroundColor: '#F8C51C40',
  },
}));

const DraftComments = (props) => {
  const { commentSets, reviewId, commentDueAt } = props;
  const classes = useStyles();

  const editDraftComments = (commentSet) => {
    navigate(
      `${process.env.GATSBY_ORS_BASE_URL}/submission/${reviewId}/manage-comments/developer/${commentSet.organization.id}/author/${commentSet.author.id}/${commentSet.type}`,
    );
  };

  return (
    <Box my={2}>
      {commentSets.map((commentSet) => {
        return (
          <React.Fragment key={commentSet.id}>
            <Box className={clsx(classes.draftBox, 'px-4 py-4 my-2 flex items-center')}>
              <Box style={{ flexGrow: 12 }}>
                <Typography variant="body1" className="font-bold m-0 p-0 text-lg w-full">
                  Draft Comments -{' '}
                  {`${commentSet.organization.name}: ${commentSet.author.displayName}`}
                </Typography>
                <Typography variant="body2" className="p-0 m-0">
                  Edits can be made until comment deadline&nbsp;
                  {moment.unix(commentDueAt).format('LL')}
                </Typography>
              </Box>
              <Box>
                <Button
                  className="font-medium py-2 px-10 text-base"
                  variant="outlined"
                  color="primary"
                  onClick={() => editDraftComments(commentSet)}
                >
                  Edit Draft
                </Button>
              </Box>
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

DraftComments.propTypes = {
  commentSets: PropTypes.array.isRequired,
  reviewId: PropTypes.string.isRequired,
  commentDueAt: PropTypes.number,
};

export default DraftComments;
