import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Typography, List, ListItem, Collapse, IconButton, Button } from '@material-ui/core';
import {
  RiFileTextLine as FileTextIcon,
  RiFileLine as FileIcon,
  RiDownload2Line as DownloadIcon,
} from 'react-icons/ri';
import { Utils } from '@eisc-frontend/shared-ui';
import { MdExpandLess as ExpandLessIcon, MdExpandMore as ExpandMoreIcon } from 'react-icons/md';
import { GlobalContext } from '../../../contexts/GlobalContext';

import DownloadProgress from './DownloadProgress';

import { useReviewDownloads } from '../../../adapters';
import { isStaffUser } from '../../../contexts/auth';
import { format } from 'date-fns';

const DetailsDocuments = (props) => {
  const globalContext = useContext(GlobalContext);

  // eslint-disable-next-line react/prop-types
  const { labels, isPrinting, setUploadDialogOpen, attachments, reviewId, isProponent } = props;
  const totalDocs = attachments?.length || 0;
  const [expanded, setExpanded] = useState(true);
  const [referenceId, setReferenceId] = useState();
  const { enqueueDownloadAttachments } = useReviewDownloads();
  const isStaff = isStaffUser();

  const totalSizeHandler = () => {
    return Utils.getFileSize(
      attachments.reduce((acc, current) => {
        return acc + current?.file?.fileSize;
      }, 0),
    );
  };

  const downloadFile = (url: any) => {
    window.open(url, '_blank');
  };

  const triggerAttachmentsDownload = async () => {
    if (referenceId) {
      globalContext.showMessage({
        title: 'Download in Process',
        body: 'Please wait for the current download to finish to start a new download',
        variant: 'error',
      });
    } else {
      const downloadRef = await enqueueDownloadAttachments(reviewId);
      setReferenceId(downloadRef);
    }
  };

  const formatDate = (date: any) => {
    return format(new Date(date), 'PPp');
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <FileTextIcon className="text-secondary" size={32} />
          <Typography variant="h2" className="p-0 ml-2 font-semibold">
            Documents
          </Typography>
        </div>
        <div className="flex items-center">
          {totalDocs > 0 && (
            <div className="bg-beige pr-2.5 pl-5 flex items-center justify-between border border-grey rounded">
              <Typography className="p-0 font-medium leading-9 mr-5" variant="body1">
                Download All Files ({totalSizeHandler()})
              </Typography>
              <IconButton onClick={() => triggerAttachmentsDownload()}>
                <DownloadIcon size={24} />
              </IconButton>
            </div>
          )}
          <div className="noPrint items-center p-1.5 ml-2.5 border border-black rounded">
            <IconButton className="text-secondary" onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLessIcon size={16} /> : <ExpandMoreIcon size={16} />}
            </IconButton>
          </div>
        </div>
      </div>
      {(isProponent || isStaff) && (
        <div className="flex justify-end mt-2.5">
          <Button
            className="py-2 px-9"
            onClick={() => setUploadDialogOpen(true)}
            variant="contained"
            color="primary"
            size="large"
          >
            Upload Document
          </Button>
        </div>
      )}
      <Collapse in={isPrinting || expanded}>
        <div className="my-4">
          <div className="border border-gray rounded">
            <div className="mx-8 my-6">
              <Typography variant="h5" className="m-0 p-0 mb-2.5">
                {totalDocs > 0
                  ? `${labels.documentsPrefix} ${totalDocs} ${
                      totalDocs !== 1 ? 'documents' : 'document'
                    }`
                  : labels.noDocuments}
              </Typography>
              {attachments?.length > 0 && (
                <List className="m-0 p-0">
                  {attachments
                    .sort((el1, el2) => el2.createdAt - el1.createdAt)
                    .map((item) => {
                      return (
                        <ListItem key={item.id} className="bg-transparent">
                          <Button
                            className="text-text bg-secondaryFaded py-0"
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<FileIcon className="text-text" />}
                            endIcon={<DownloadIcon />}
                            onClick={() => downloadFile(item.file?.generatedUrl)}
                          >
                            <div className="flex-col">
                              <Typography
                                className="text-text text-left font-semibold max-w-full p-0 m-1.5"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  wordBreak: 'break-word',
                                }}
                                variant="body1"
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                className="text-text text-left overflow-ellipsis whitespace-nowrap overflow-hidden max-w-full p-0 m-1.5"
                                variant="body1"
                              >
                                {formatDate(item.file.createdAt)}
                              </Typography>
                            </div>
                          </Button>
                        </ListItem>
                      );
                    })}
                </List>
              )}
            </div>
          </div>
        </div>
      </Collapse>

      {referenceId && (
        <DownloadProgress referenceId={referenceId} setReferenceId={setReferenceId} />
      )}
    </div>
  );
};

DetailsDocuments.propTypes = {
  labels: PropTypes.any.isRequired,
  isPrinting: PropTypes.any,
  setUploadDialogOpen: PropTypes.func,
  attachments: PropTypes.array,
  reviewId: PropTypes.string,
  isProponent: PropTypes.bool,
};

export default DetailsDocuments;
