import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Modal } from '@eisc-frontend/shared-ui';

const CrownConsultationModal = ({ isDialogOpen, onConfirm }) => {
  return (
    <Modal
      isVisible={isDialogOpen}
      titleText="Crown Consultation"
      modalText={
        <Box>
          <Typography variant="body1">
            If you have any observations or concerns regarding potential impacts to the environment
            or to wildlife harvesting, we would appreciate hearing from you.
          </Typography>

          <Typography variant="body1">
            Your written submission, supported by a clear explanation and rationale for your
            comments, will aid the Environmental Impact Screening Committee in reaching a screening
            determination for this project.
          </Typography>
        </Box>
      }
      confirmText="I understand"
      onConfirm={onConfirm}
    />
  );
};

CrownConsultationModal.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CrownConsultationModal;
