import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  LinearProgress,
  IconButton,
} from '@material-ui/core';
import { MdError as ErrorIcon } from 'react-icons/md';
import { StaticAlert } from '@eisc-frontend/shared-ui';
import { useUploadForm } from '../adapters';
import {
  RiUploadCloud2Line as CloudUploadIcon,
  RiDeleteBin2Line as DeleteIcon,
  RiFileLine as FileIcon,
  RiCloseLine as CloseIcon,
} from 'react-icons/ri';
function UploadDocumentsDialog(props: any) {
  const { isDialogOpen, onCloseDialog, reviewId } = props;
  const [uploadingErrors, setUploadingErrors] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [isValid, setValid] = useState(false);
  const { upload, success, isFetching, uploadedFiles } = useUploadForm();

  useEffect(() => {
    if (attachments.length > 0 && !isFetching) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [attachments, isFetching]);

  useEffect(() => {
    setUploadingErrors([]);
    setAttachments([]);
  }, [isDialogOpen]);

  const handleDrop = (files) => {
    setUploadingErrors([]);
    setAttachments([...attachments, ...files]);
  };

  const handleDelete = (fileIndex) => {
    setAttachments(attachments.filter((_, i) => i !== fileIndex));
  };

  const handleReject = (upload: any) => {
    setUploadingErrors([]);
    if (upload.length) {
      const errorMsg = upload.map((file) => {
        if (file.errors.length) {
          return file.errors.map((error) => {
            if (error.code === 'file-too-large') {
              return `${file.file.path} is larger than the maximum allowed upload size of 10MB`;
            } else {
              return `${file.path} - ${error.message}`;
            }
          });
        } else {
          return null;
        }
      });
      setUploadingErrors(errorMsg);
    }
  };

  const submitAttachments = () => {
    upload(attachments, reviewId);
  };

  useEffect(() => {
    if (success && uploadedFiles) {
      onCloseDialog(uploadedFiles);
    }
  }, [success, uploadedFiles]);

  return (
    <Dialog open={isDialogOpen} onClose={onCloseDialog} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle className="p-0" disableTypography>
        <div className="flex my-0 justify-between items-center mx-5">
          <Typography variant="h4" className="leading-12 text-text pb-0 font-semibold">
            Upload Documents
          </Typography>
          <IconButton onClick={onCloseDialog}>
            <CloseIcon size={24} color="black" />
          </IconButton>
        </div>
      </DialogTitle>
      <div className="bg-primary h-0.5 w-full"></div>

      <DialogContent>
        <div className="m-2">
          {uploadingErrors?.length > 0 && (
            <div className="m-2">
              <StaticAlert pt={1} mx={4} severity="error" icon={<ErrorIcon />}>
                <ul>
                  {uploadingErrors.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}
                </ul>
              </StaticAlert>
            </div>
          )}

          <Dropzone
            onDrop={(files) => handleDrop(files)}
            maxSize={10485760}
            onDropRejected={handleReject}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="border-primary border-2 border-dashed bg-beige p-10 m-1 cursor-pointer"
                {...getRootProps()}
              >
                <input {...getInputProps()} multiple={false} />
                <div className="flex justify-between items-center">
                  <div>
                    <CloudUploadIcon size={64} className="text-primary" />
                  </div>
                  <div className="ml-9">
                    <Typography variant="subtitle2" className="leading-5">
                      Drag and drop your files here to add them to this screening item or
                    </Typography>
                    <Typography variant="subtitle2" className="leading-5">
                      Click here to select the file from your computer
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
          <Typography variant="h5" className="leading-12 mt-2.5">
            {attachments.length} File{attachments.length !== 1 && 's'} Ready for Upload
          </Typography>
          <div className="my-2 flex flex-col flex-wrap">
            {attachments.map((file: any, index) => {
              return (
                <div className="mb-2.5" key={index}>
                  <Button
                    className="bg-secondaryFaded text-base whitespace-nowrap overflow-ellipsis px-3 py-1.5"
                    startIcon={<FileIcon size={24} className="mr-4" />}
                    endIcon={<DeleteIcon className="text-red-500 ml-4" size={24} />}
                    disabled={isFetching}
                    onClick={() => handleDelete(index)}
                  >
                    {file.name}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>

      <Divider />
      {isFetching && <LinearProgress color={'primary'} />}

      <DialogActions>
        <Button
          variant="outlined"
          className="border-black text-base px-8 h-9"
          onClick={onCloseDialog}
          disabled={isFetching}
        >
          Cancel
        </Button>
        <Button
          className="px-6 h-9"
          variant="contained"
          color="primary"
          onClick={submitAttachments}
          disabled={!isValid}
        >
          Upload Files
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UploadDocumentsDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
  reviewId: PropTypes.string,
};

export default UploadDocumentsDialog;
