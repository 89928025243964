import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { Formik, Form as FormikForm } from 'formik';
import { differenceInDays, startOfDay } from 'date-fns';

import { GlobalContext } from '../../../contexts/GlobalContext';

import { Overlay, ReviewStatusEnum } from '@eisc-frontend/shared-ui';

import ReviewInternalDataModel from './../../../models/ReviewInternalDataModel';
import { StaffInternalDataSchema } from '../../../utils';
import { useUpdateReview, useUpdateReviewStatus } from '../../../adapters';

import Deadlines from './Deadlines';
import DeliveryOptions from './DeliveryOptions';
import StatusChangeConfirmationModal from '../../StatusChangeConfirmationModal';
import SentNotifications from './SentNotifications';
import { EISCUseSectionStatus } from '../../../constants';

const StaffSection = ({ details }) => {
  const globalContext = useContext(GlobalContext);
  const initialValues = new ReviewInternalDataModel(details);
  const [submitValues, setSubmitValues] = useState<any>();
  const {
    success: updateStatusSuccess,
    updateReviewStatus,
    errors: statusErrors,
  } = useUpdateReviewStatus();
  const [modal, setModal] = useState({
    title: '',
    body: '',
    confirmText: '',
    cancelText: '',
    status: '',
    open: false,
  });

  const {
    success: updateInternalSuccess,
    updateReview,
    errors: updateReviewErrors,
    isFetching,
  } = useUpdateReview();

  useEffect(() => {
    if (updateInternalSuccess) {
      window.location.reload();
    }
  }, [updateInternalSuccess]);

  useEffect(() => {
    const errors = [...statusErrors, ...updateReviewErrors];
    if (errors.length > 0) {
      globalContext.showMessage({
        title: 'Error',
        variant: 'error',
        body: errors,
      });
    }
  }, [statusErrors, updateReviewErrors]);

  const submit = async (values) => {
    await updateReview(details.id, values);
  };

  useEffect(() => {
    if (updateStatusSuccess) {
      submit(submitValues);
    }
  }, [updateStatusSuccess]);

  const handleStatusChange = async () => {
    setModal({ title: '', body: '', confirmText: '', cancelText: '', status: '', open: false });
    await updateReviewStatus(details.id, modal.status);
  };

  const handleSubmit = (values) => {
    const dueDate = startOfDay(new Date(values.commentDueAt));
    const today = startOfDay(new Date(Date.now()));

    if (
      details.status === ReviewStatusEnum.ClosedForComments &&
      differenceInDays(dueDate, today) > 0
    ) {
      setSubmitValues(values);
      setModal({
        title: 'Submission Status Change',
        body: 'Changing the comment deadline to a future date will automatically change the status of this submission to "Awaiting Comments"',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        status: ReviewStatusEnum.AwaitingComments,
        open: true,
      });
    } else if (
      details.status === ReviewStatusEnum.AwaitingComments &&
      differenceInDays(dueDate, today) < 0
    ) {
      setSubmitValues(values);
      setModal({
        title: 'Submission Status Change',
        body: 'Changing the comment deadline to yesterday\'s date or earlier will automatically change the status of this submission to "Closed for Comments"',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        status: ReviewStatusEnum.ClosedForComments,
        open: true,
      });
    } else {
      submit(values);
    }
  };

  const handleCloseModal = () => {
    setModal({
      title: '',
      body: '',
      confirmText: '',
      cancelText: '',
      status: '',
      open: false,
    });
  };

  return (
    <Overlay loading={isFetching}>
      <div className="mx-4">
        {EISCUseSectionStatus.includes(details.status) && (
          <Formik
            initialValues={initialValues}
            validationSchema={StaffInternalDataSchema}
            onSubmit={handleSubmit}
          >
            <FormikForm>
              <Deadlines />
              <DeliveryOptions />

              <div className="mt-5 flex justify-end">
                <Button size="large" variant="contained" color="primary" type={'submit'}>
                  Save Changes
                </Button>
              </div>
            </FormikForm>
          </Formik>
        )}
        {details.sentNotifications && <SentNotifications submission={details} />}
      </div>
      <StatusChangeConfirmationModal
        isModalOpen={modal.open}
        onCancel={handleCloseModal}
        onConfirm={handleStatusChange}
        title={modal.title}
        body={modal.body}
        confirmText={modal.confirmText}
        cancelText={modal.cancelText}
      />
    </Overlay>
  );
};

StaffSection.propTypes = {
  details: PropTypes.any.isRequired,
};

export default StaffSection;
