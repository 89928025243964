import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { MdExpandLess as ExpandLessIcon, MdDescription as DescriptionIcon } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useReviewDownloads } from '../../../adapters';

const pullingInterval = parseInt(process.env.GATSBY_COMMENTS_DOWNLOAD_PULLING_INTERVAL || 5000, 10);
const maxRetries = parseInt(process.env.GATSBY_COMMENTS_DOWNLOAD_MAX_RETRIES || 20, 10);

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 500,
    position: 'fixed',
    bottom: 10,
    right: 0,
    width: '100%',
    maxWidth: '390px',
  },
  header: {
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 8,
    paddingBottom: 8,
    color: theme.palette.common.white,
  },
  title: {
    color: theme.palette.common.white,
  },
  expandIcon: {
    color: theme.palette.common.white,
  },
  content: {
    display: 'flex',
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 8,
    paddingBottom: 0,
    justifyContent: 'space-between',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
    backgroundColor: theme.palette.common.white,
  },
  fileIcon: {
    color: '#E67337',
  },
}));

const DownloadProgress = ({
  referenceId,
  setReferenceId,
  setAlertModalOpen,
  setAlertModalMessage,
}) => {
  const classes = useStyles();

  const checkCount = useRef(0);
  const { downloadAttachments } = useReviewDownloads();

  const checkDownload = async () => {
    const { name, blobUrl } = await downloadAttachments(referenceId);

    if (name && blobUrl) {
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = name;
      downloadLink.click();
      setReferenceId(null);
    } else if (checkCount.current < maxRetries) {
      checkCount.current = checkCount.current + 1;
    } else {
      setAlertModalMessage('Your download has been failed. Please try again later.');
      setAlertModalOpen(true);
      setReferenceId(null);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => checkDownload(), pullingInterval);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography variant="subtitle1" className={classes.title}>
          Preparing Download
        </Typography>
        <ExpandLessIcon className={classes.expandIcon} />
      </Box>

      <Box className={classes.content}>
        <Box display={'flex'}>
          <DescriptionIcon className={classes.fileIcon} />
          <Typography variant="body1">My report</Typography>
        </Box>
        <CircularProgress size={20} />
      </Box>
    </Box>
  );
};

DownloadProgress.propTypes = {
  referenceId: PropTypes.string.isRequired,
  setReferenceId: PropTypes.func,
  setAlertModalOpen: PropTypes.func,
  setAlertModalMessage: PropTypes.func,
};

export default DownloadProgress;
