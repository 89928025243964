import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { fromUnixTime, format } from 'date-fns';
import { RiFileTextLine as FileIcon } from 'react-icons/ri';

const SentNotifications = (props: any) => {
  const { submission } = props;

  const downloadFile = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className="pt-4">
      {submission.sentNotifications && (
        <div>
          <div className="flex items-center mb-8">
            <FileIcon className="text-secondary" size={32} />
            <Typography variant="h2" className="p-0 ml-2 font-semibold">
              Notification History
            </Typography>
          </div>
          {submission.sentNotifications.map((item) => {
            return (
              <div key={item.id} className="flex items-center justify-start mt-1">
                <Typography variant="body1" className="p-0 w-48">
                  {format(fromUnixTime(item.sentAt), 'PPp')}
                </Typography>
                <Button
                  className="text-text bg-secondaryFaded p-0 ml-2"
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<FileIcon className="text-secondary" />}
                  onClick={() => downloadFile(item.file)}
                >
                  <Typography
                    className="text-text overflow-ellipsis font-semibold whitespace-nowrap overflow-hidden max-w-full p-0 m-.5"
                    variant="body1"
                  >
                    View List
                  </Typography>
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

SentNotifications.propTypes = {
  submission: PropTypes.object.isRequired,
};

export default SentNotifications;
