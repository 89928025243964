import React, { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { RiMailSendLine as MailIcon } from 'react-icons/ri';

import { RenderAutocompleteField, AUTOCOMPLETE_DEFAULT_PAGE_SIZE } from '@eisc-frontend/shared-ui';
import { useGetAllUsers, useDistributionList } from '../../../adapters';

function DeliveryOptions() {
  const { items: dlItems, getAll } = useDistributionList();
  const { onLoad: loadUsers, allUsers } = useGetAllUsers();
  const { values, setFieldValue } = useFormikContext<{
    distributionListIds: string[];
    distributionUserIds: string[];
  }>();

  const [distributionListOptions, setDistributionListOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [distributionListFilter, setDistributionListFilter] = useState<string>('');
  const [userOptions, setUserOptions] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (distributionListFilter || distributionListFilter === '') {
      getAll({
        take: AUTOCOMPLETE_DEFAULT_PAGE_SIZE,
        searchName: distributionListFilter,
        includeUserCount: true,
      });
    }
  }, [distributionListFilter]);

  useEffect(() => {
    if (dlItems) {
      setDistributionListOptions(dlItems.map((dl) => ({ value: dl.id, label: dl.name })));
    }
  }, [dlItems]);

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (allUsers) {
      setUserOptions(allUsers.map((user) => ({ value: user.id, label: user.displayName })));
    }
  }, [allUsers]);

  useEffect(() => {
    let totalUsers = values.distributionUserIds?.length || 0;

    if (values.distributionListIds?.length > 0) {
      const selectedDlIds = values.distributionListIds.map((i: any) => i.value || i);
      totalUsers += dlItems.reduce((acc, dl) => {
        if (selectedDlIds.includes(dl.id)) {
          return acc + (dl.userCount || 0);
        }
        return acc;
      }, 0);
    }

    setFieldValue('totalListRecipients', totalUsers);
  }, [values.distributionListIds, values.distributionUserIds]);

  return (
    <div className="mt-4 pb-6">
      <div className="flex items-center mb-8">
        <MailIcon size={32} className="text-secondary" />
        <Typography variant="h2" className="p-0 ml-2 font-semibold" component="div">
          Delivery Options
        </Typography>
      </div>
      <div className="bg-beige p-4 border rounded">
        <div>
          <div>
            <Typography variant="subtitle1">
              Select Distribution lists that will be informed about this review
            </Typography>
          </div>
          <Field
            name="distributionListIds"
            component={RenderAutocompleteField}
            options={distributionListOptions}
            onFilterChange={setDistributionListFilter}
          />
        </div>
        <div className="flex items-center py-4">
          <div className="w-full border-0 border-b border-grey" />
          <Typography variant="subtitle1">And/Or</Typography>
          <div className="w-full border-0 border-b border-grey" />
        </div>
        <div>
          <div>
            <Typography variant="subtitle1">Select user(s) from the system</Typography>
          </div>
          <Field
            name="distributionUserIds"
            multiple
            component={RenderAutocompleteField}
            options={userOptions}
          />
        </div>
      </div>
    </div>
  );
}

export default DeliveryOptions;
