import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import {
  RiArrowRightSLine as ArrowRightIcon,
  RiQuestionAnswerLine as CommentsIcon,
} from 'react-icons/ri';
import CrownConsultationModal from './CrownConsultationModal';
import PublicComments from './PublicComments/PublicComments';
import DraftComments from './DraftComments';
import AttachmentsList from './AttachmentsList';
import { useGetCommentSet } from '../../../adapters';

import { CommentType, MailTo, ReviewStatusEnum } from '@eisc-frontend/shared-ui';

import { CommentStatusEnum, SystemAdminEmail } from '../../../constants';
import { getUser, isStaffUser } from '../../../contexts/auth';

const user = getUser();
const isStaff = isStaffUser();

const useStyles = makeStyles((theme) => ({
  dense: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  card: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '4px',
  },
  link: {
    fontWeight: 'normal',
    color: '#009B58',
    cursor: 'pointer',
  },
  linkIcon: {
    position: 'relative',
    bottom: '-5px',
  },
  commentBox: {
    minHeight: '210px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const DetailsComments = (props) => {
  const classes = useStyles();
  const {
    reviewId,
    isPrinting,
    commentDueAt,
    reviewType,
    labels,
    allowSubmitComments,
    allowSubmitResponses,
    allowSubmitInformationRequests,
    allowRespondInformationRequests,
    status,
  } = props;
  const [modal, setModal] = useState<{
    open: boolean;
    callback: (type: CommentType) => void;
    type: CommentType;
    isResponse: boolean;
  }>({ open: false, callback: null, type: CommentType.Comment, isResponse: false });
  const [publishedCommentSets, setPublishedCommentSets] = useState([]);
  const [draftCommentSets, setDraftCommentSets] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [isUserAddressed, setIsUserAddressed] = useState(true);

  const {
    isFetching: isFetchingCommentSet,
    getCommentSetsByParams,
    getPublicCommentSets,
  } = useGetCommentSet();

  const refs = publishedCommentSets.reduce((acc, commentSet) => {
    commentSet.comments.forEach((comment) => {
      comment.attachments?.forEach((file) => {
        if (!acc[file.id]) {
          acc[file.id] = React.createRef();
        }
      });
    });

    return acc;
  }, {});

  useEffect(() => {
    (async () => {
      const commentSets = user
        ? await getCommentSetsByParams(reviewId, null, null, true, true)
        : await getPublicCommentSets(reviewId, true);

      if (commentSets) {
        const publishedCS = commentSets.filter((cs) => cs.status === CommentStatusEnum.PUBLISHED);

        const draftCS = commentSets.filter(
          (cs) => cs.status === CommentStatusEnum.DRAFT && (isStaff || cs.author.id === user.id),
        );
        setDraftCommentSets(draftCS);
        setPublishedCommentSets(publishedCS);
      }
    })();
  }, []);

  const getAttachmentsSize = () => {
    let attachments = 0;
    // eslint-disable-next-line array-callback-return
    publishedCommentSets.map((commentSet) => {
      commentSet.comments.forEach((comment) => {
        attachments += comment.attachments?.length || 0;
      });
    });
    return attachments;
  };

  const openModal = (
    callback: (type: CommentType) => void,
    type: CommentType,
    isResponse: boolean,
  ) => {
    if ((reviewId && !localStorage.getItem(`REVIEW_${reviewId}`)) || (reviewId && !user)) {
      setModal({ open: true, type, isResponse, callback });
      localStorage.setItem(`REVIEW_${reviewId}`, 'seen');
    } else {
      callback(type);
    }
  };

  const openAddCommentsScreen = (type: CommentType) => {
    if (!isStaff && user.organizations?.length === 1) {
      const developerId = user.organizations[0].id;
      navigate(
        `${process.env.GATSBY_ORS_BASE_URL}/submission/${reviewId}/manage-comments/developer/${developerId}/author/${user.id}/${type}`,
      );
    } else {
      navigate(
        `${process.env.GATSBY_ORS_BASE_URL}/submission/${reviewId}/select-comments-reviewer/${type}`,
      );
    }
  };

  const openAddResponsesScreen = (type: CommentType) => {
    if (!isStaff) {
      const developerId = user.organizations[0].id;
      navigate(
        `${process.env.GATSBY_ORS_BASE_URL}/submission/${reviewId}/manage-responses/developer/${developerId}/author/${user.id}/${type}`,
      );
    } else {
      navigate(
        `${process.env.GATSBY_ORS_BASE_URL}/submission/${reviewId}/select-response-proponent/${type}`,
      );
    }
  };

  const onCrownConsultationConfirm = (type, isResponse) => {
    if (!user) {
      if (!reviewId) {
        navigate(`${process.env.GATSBY_ORS_BASE_URL}`);
      } else if (isResponse) {
        navigate(
          `${process.env.GATSBY_ORS_BASE_URL}/submission/${reviewId}/select-response-proponent/${type}`,
        );
      } else {
        navigate(
          `${process.env.GATSBY_ORS_BASE_URL}/submission/${reviewId}/select-comments-reviewer/${type}`,
        );
      }
    } else {
      modal.callback(type);
    }
    setModal({ open: false, type: CommentType.Comment, isResponse: false, callback: null });
  };

  const attachmentsSize = getAttachmentsSize();

  const toggleExpanded = (commentSetId, isExpanded) => {
    let newExpanded = [...expanded];

    if (isExpanded) {
      newExpanded.push(commentSetId);
    } else {
      newExpanded = newExpanded.filter((i) => i !== commentSetId);
    }

    setExpanded(newExpanded);
  };

  /**
   * Set isUserAddressed based on whether user has been addressed in published IRs
   * This is only for regular users who are logged in
   *  */
  useEffect(() => {
    if (user && !isStaff) {
      const addressToOrgIds = publishedCommentSets
        .filter((el) => el.type === CommentType.InformationRequest)
        .map((el) => el.comments)
        .flat()
        .map((el) => el.addressTo.id);
      const userOrgIds = user.organizations.map((el) => el.id);

      const isUserAddressed = addressToOrgIds.some((el) => userOrgIds.includes(el));
      setIsUserAddressed(isUserAddressed);
    }
  }, [publishedCommentSets]);

  return (
    <div className="pb-4">
      <div className="flex">
        <div className="flex items-center">
          <CommentsIcon size={28} className="mr-2.5 text-secondary" />
          <Typography variant="h2" className="p-0">
            Comments
          </Typography>
        </div>
      </div>

      {status === ReviewStatusEnum.AwaitingComments && (
        <Box className="noPrint mt-4">
          <Typography>
            To provide comments on this submission, please contact the{' '}
            {<MailTo email={SystemAdminEmail.email} text={SystemAdminEmail.name} />} to be assigned
            to an organization.
          </Typography>
        </Box>
      )}

      {isFetchingCommentSet && <LinearProgress />}

      <Box my={2}>
        <Box className="noPrint">
          <Box my={3}>
            {user && (
              <Box className="noPrint">
                {allowSubmitComments && (
                  <Box my={2}>
                    <Typography className="m-0 p-0 w-full text-2xl font-semibold">
                      {labels.submitCommentsOnlineTitle}
                    </Typography>
                  </Box>
                )}

                <Grid container spacing={2}>
                  {(allowSubmitComments || allowSubmitResponses) && (
                    <Grid item xs={12} sm={6}>
                      <div className="flex flex-col justify-between space-y-2 p-6 border rounded">
                        <Box className="pb-0">
                          <Typography variant="h4" className="text-lg mb-2">
                            {labels.submitCommentsOnline}
                          </Typography>
                          <Typography variant="body1">{labels.submitCommentsOnlineBox}</Typography>
                        </Box>
                        <Box>
                          {allowSubmitComments && (
                            <Box className="max-w-72 mb-3">
                              <Button
                                className="w-full"
                                variant="outlined"
                                size="large"
                                endIcon={<ArrowRightIcon />}
                                onClick={() =>
                                  openModal(openAddCommentsScreen, CommentType.Comment, false)
                                }
                              >
                                {labels.addCommentsOnlineText}
                              </Button>
                            </Box>
                          )}
                          {allowSubmitResponses && (
                            <Box className="max-w-72">
                              <Button
                                className="w-full"
                                variant="outlined"
                                size="large"
                                endIcon={<ArrowRightIcon />}
                                onClick={() =>
                                  openModal(openAddResponsesScreen, CommentType.Comment, true)
                                }
                              >
                                {labels.addResponseOnlineText}
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    {(allowSubmitInformationRequests || allowSubmitResponses) && (
                      <div className="flex flex-col justify-between space-y-2 p-6 border rounded">
                        <Box className="pb-0">
                          <Typography variant="h4" className="text-lg mb-2">
                            {labels.informationRequest}
                          </Typography>
                          <Typography variant="body1">
                            {labels.submitInformationRequestOnlineBox}
                          </Typography>
                        </Box>
                        <Box>
                          {allowSubmitInformationRequests && (
                            <Box className="max-w-72 mr-12 mb-3">
                              <Button
                                className="w-full mr-6"
                                variant="outlined"
                                size="large"
                                endIcon={<ArrowRightIcon />}
                                onClick={() =>
                                  openModal(
                                    openAddCommentsScreen,
                                    CommentType.InformationRequest,
                                    true,
                                  )
                                }
                              >
                                {labels.addInformationRequestOnlineText}
                              </Button>
                            </Box>
                          )}

                          {allowRespondInformationRequests && isUserAddressed && (
                            <Box className="max-w-72 mr-12">
                              <Button
                                className="w-full"
                                variant="outlined"
                                size="large"
                                endIcon={<ArrowRightIcon />}
                                onClick={() =>
                                  openModal(
                                    openAddResponsesScreen,
                                    CommentType.InformationRequest,
                                    false,
                                  )
                                }
                              >
                                {labels.addResponseOnlineText}
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
            {/** Attachments menu */}
            <Box my={2}>
              {attachmentsSize > 0 ? (
                <AttachmentsList
                  commentSets={publishedCommentSets}
                  attachmentsSize={attachmentsSize}
                  refs={refs}
                  labels={labels}
                  toggleExpanded={toggleExpanded}
                />
              ) : (
                <Typography variant="body1">{labels.noAttachments}</Typography>
              )}
            </Box>
          </Box>
        </Box>
        {/** Modal Box */}
        <CrownConsultationModal
          isDialogOpen={modal.open}
          onConfirm={() => onCrownConsultationConfirm(modal.type, modal.isResponse)}
        />

        {/** Draft Comments */}
        {draftCommentSets.length > 0 && (
          <DraftComments
            commentSets={draftCommentSets}
            reviewId={reviewId}
            commentDueAt={commentDueAt}
          />
        )}

        {/** Public comments */}
        {!isFetchingCommentSet && publishedCommentSets.length === 0 ? (
          <Box my={2}>
            <Card elevation={0} className={classes.card}>
              <Box mx={4} my={3}>
                <Typography variant="body1" className={classes.dense}>
                  {labels.itemNoComments}
                </Typography>
              </Box>
            </Card>
          </Box>
        ) : (
          <PublicComments
            labels={labels}
            commentSets={publishedCommentSets}
            isPrinting={isPrinting}
            refs={refs}
            isIR={reviewType === CommentType.InformationRequest}
            isStaff={isStaff}
            reviewId={reviewId}
            expanded={expanded}
            toggleExpanded={toggleExpanded}
          />
        )}
      </Box>
    </div>
  );
};

DetailsComments.propTypes = {
  reviewId: PropTypes.string.isRequired,
  isPrinting: PropTypes.bool,
  commentDueAt: PropTypes.number,
  reviewType: PropTypes.string,
  labels: PropTypes.any.isRequired,
  allowSubmitComments: PropTypes.bool,
  allowSubmitResponses: PropTypes.bool,
  allowSubmitInformationRequests: PropTypes.bool,
  allowRespondInformationRequests: PropTypes.bool,
  developerId: PropTypes.string,
  isStakeholder: PropTypes.bool,
  status: PropTypes.string,
};

export default DetailsComments;
