import React, { useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import { Map } from '@eisc-frontend/shared-ui';

import { RiMapPinLine as RiMapPinLineIcon } from 'react-icons/ri';
import html2canvas from 'html2canvas';
import { toJpeg } from 'html-to-image';

type DetailsLocationProps = {
  location: { id: string; latitude: string; longitude: string }[];
  commonName?: string;
  ntsMapSheet?: string;
  mapImage?: string;
  isPrinting?: boolean;
  onLoad?(arg0: { screenshotLocation: () => Promise<string> });
};

const DetailsLocation = (props: DetailsLocationProps) => {
  const { location, commonName, ntsMapSheet, mapImage } = props;

  const mapRef = useRef(null);

  const formattedCoordinates = location?.map((el) => ({ lat: +el.latitude, lng: +el.longitude }));

  const screenshotLocation = async () => {
    if (mapRef.current) {
      const dataUrl = await toJpeg(mapRef?.current.firstChild);

      return dataUrl;
    }

    return '';
  };

  if (props.onLoad) {
    props.onLoad({ screenshotLocation });
  }

  return (
    <div>
      <div className="flex items-center">
        <RiMapPinLineIcon size={32} className="text-secondary" />
        <Typography variant="h2" className="p-0 ml-2 font-semibold" component="div">
          Location
        </Typography>
      </div>
      <div className="flex flex-wrap mt-6 px-6">
        {location?.map((el) => (
          <div key={el.id} className="flex-column w-1/4 mb-2">
            <Typography variant="body1" component="div">
              {el.latitude}
            </Typography>
            <Typography variant="body1" component="div">
              {el.longitude}
            </Typography>
          </div>
        ))}
      </div>
      {!!location?.length && (
        <div className="h-px-360 mt-4" ref={mapRef}>
          {mapImage && <img src={mapImage} />}
          {!mapImage && <Map coordinates={formattedCoordinates} />}
        </div>
      )}
      {!!commonName?.length && (
        <div className="flex-col mt-6">
          <Typography variant="subtitle1">24. Common or Traditional Name</Typography>
          <Typography
            className="text-text text-left max-w-full"
            variant="body1"
            style={{
              display: 'flex',
              flexDirection: 'column',
              wordBreak: 'break-word',
            }}
          >
            {commonName}
          </Typography>
        </div>
      )}
      {!!ntsMapSheet?.length && (
        <div className="flex-col mt-6">
          <Typography variant="subtitle1">25. NTS Map Sheet #</Typography>
          <Typography
            className="text-text text-left max-w-full"
            variant="body1"
            style={{
              display: 'flex',
              flexDirection: 'column',
              wordBreak: 'break-word',
            }}
          >
            {ntsMapSheet}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default DetailsLocation;
